<template>
  <div v-if="links" class="border border-secondary-300 px-4 py-3 flex flex-col">
    <div class="clear-both border-b-2 border-secondary-300 pb-2 mb-3 text-lg font-semibold font-serif">
      <div class="float-left text-lg text-bold font-serif">
        Länkar
      </div>
      <div class="float-right">
        <button v-if="user.role == 'admin'" class="btn-blue-outline font-bold" @click="show({ type: 'upload_link', title: '', description: '', link: '' })">Lägg till</button>
      </div>
    </div>
    <div class="py-2" v-if="links.length == 0">
      Inga data
    </div>
    <div v-else class="flex flex-col">
      <div class="clear-both border-b border-secondary-300 py-2" v-for="(l, i) in links" :key="i">
        <a :href="l.link" class="float-left font-serif link" target="_blank">{{ l.title }}</a>
        <span class="float-right">
          <button v-if="user.role == 'admin'" class="btn-secondary-outline text-xxs mr-2" @click="show(l)">
            <BaseIcon icon="pencil-alt" />
          </button>
          <button v-if="user.role == 'admin'" class="btn-red-outline text-xxs" @click="deleteInfoPrompt(l)">
            <BaseIcon icon="trash" />
          </button>
        </span>
      </div>
    </div>
    <UpdateInternalInfo type="link" :internalInfo="updateInfo" @updateInternalInfo="getLinks" />
  </div>
</template>

<script>
import UpdateInternalInfo from "@/components/modal/update_internal_info";

export default {
  components: {
    UpdateInternalInfo,
  },
  data() {
    return {
      user: this.$store.state.user,
      links: null,
      updateInfo: null,
    };
  },
  methods: {
    async getLinks() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/links`);
        this.links = response.data.news;
      } catch (error) {
        this.handleError(error);
      }
    },

    deleteInfoPrompt(info) {
      this.$modal.show("dialog", {
        title: `<span class="text-red-500 text-sm font-medium">Ta bort "${info.title}"?</span>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Avbryt</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Radera</div>',
            handler: () => {
              this.deleteInfo(info);
            },
          },
        ],
      });
    },

    async deleteInfo(info) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/internal-info/${info._id}`);

        this.getLinks();
        this.handleSuccess("Länken har tagits bort");
        this.$modal.hide("dialog");
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    show(link) {
      this.updateInfo = _.cloneDeep(link);
      this.$modal.show("link");
    },
  },

  created() {
    this.getLinks();
  },
};
</script>
