<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/sales/company/-" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="dollar-sign" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">{{ $t("secure_cloud.company_sales") }}</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/admin/staff" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="id-card" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">{{ $t("secure_cloud.personal") }}</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/company-info" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="info-circle" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">{{ $t("secure_cloud.info") }}</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/admin/avtal" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="file-alt" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">{{ $t("secure_cloud.agreement") }}</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/admin/kunder" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="users" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">{{ $t("secure_cloud.customer") }}</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/admin/larm" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="table" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Utleveransdokument</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/alarm-report/search" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="list" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Live larmrapport</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/config/alarm" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="cogs" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Konfigurera larm</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/admin/alarm-reminder" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="bell" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Provlarmspåminnelse</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/admin/error-logs" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="exclamation-triangle" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Alla fel</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/alarm-center/alarm-via-securcloud/-" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="angle-double-right" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Larm via SecurCloud</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/policy-routine" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="handshake" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Policy & Rutiner</div>
        </router-link>
      </div>
    </div>

    <div class="flex flex-wrap mt-6">
      <div class="w-full lg:w-1/2 lg:pr-3 mt-2">
        <News />
      </div>
      <div class="w-full lg:w-1/2 lg:pr-3 mt-2">
        <Links />
      </div>
    </div>
  </div>
</template>

<script>
import News from "@/components/news";
import Links from "@/components/link";

export default {
  title() {
    return `Admin - Dashboard`;
  },
  components: {
    News,
    Links,
  },
  data() {
    return {
      user: this.$store.state.user,
    };
  },
  created() {
    this.setPageTitle("Admin - Dashboard", "admin");
  },
};
</script>
