<template>
  <div v-if="news" class="border border-secondary-300 px-4 py-3 flex flex-col">
    <div class="clear-both border-b-2 border-secondary-300 pb-2 mb-3 text-lg font-semibold font-serif">
      <div class="float-left text-lg text-bold font-serif">
        Nyheter
      </div>
      <div class="float-right">
        <button v-if="user.role == 'admin'" class="btn-blue-outline font-bold" @click="show({ type: 'news', title: '', description: '', link: '' })">Lägg till</button>
      </div>
    </div>
    <div class="py-2" v-if="news.length == 0">
      Inga data
    </div>
    <div v-else class="flex flex-col">
      <div class="clear-both border-b border-secondary-300 py-2" v-for="(n, i) in news" :key="i">
        <span class="float-left font-serif">{{ n.title }}</span>
        <span class="float-right">
          <button class="btn-blue-outline text-xxs" v-bind:class="{ 'mr-2': user.role == 'admin' }" @click="viewNews(n)">Läs mer...</button>
          <button v-if="user.role == 'admin'" class="btn-secondary-outline text-xxs mr-2" @click="show(n)">
            <BaseIcon icon="pencil-alt" />
          </button>
          <button v-if="user.role == 'admin'" class="btn-red-outline text-xxs" @click="deleteInfoPrompt(n)">
            <BaseIcon icon="trash" />
          </button>
        </span>
      </div>
    </div>
    <div class="clear-both">
      <button class="float-left btn-secondary-outline mt-4" @click="previousPage()" v-if="page > 1"><BaseIcon icon="angle-left" class="mr-1" />Previous</button>
      <button class="float-right btn-secondary-outline mt-4" @click="nextPage()" v-if="page < totalPage">Next<BaseIcon icon="angle-right" class="ml-1" /></button>
    </div>
    <UpdateInternalInfo type="news" :internalInfo="updateInfo" @addedNew="resetPage" @updateInternalInfo="getNews" />
    <NewsView :news="newsView" />
  </div>
</template>

<script>
import UpdateInternalInfo from "@/components/modal/update_internal_info";
import NewsView from "@/components/modal/news_view";

export default {
  components: {
    UpdateInternalInfo,
    NewsView,
  },
  data() {
    return {
      user: this.$store.state.user,
      page: 1,
      totalPage: 1,
      news: null,
      updateInfo: null,
      newsView: null,
    };
  },
  methods: {
    async getNews() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/news/recent?page=${this.page}`);
        this.news = response.data.news;
        this.totalPage = response.data.totalPage;
      } catch (error) {
        this.handleError(error);
      }
    },

    deleteInfoPrompt(info) {
      this.$modal.show("dialog", {
        title: `<span class="text-red-500 text-sm font-medium">Ta bort "${info.title}"?</span>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Avbryt</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Radera</div>',
            handler: () => {
              this.deleteInfo(info);
            },
          },
        ],
      });
    },

    async deleteInfo(info) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/internal-info/${info._id}`);

        this.getNews();
        this.handleSuccess("Nyheter har tagits bort");
        this.$modal.hide("dialog");
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    previousPage() {
      this.page -= 1;
      this.getNews();
    },

    nextPage() {
      this.page += 1;
      this.getNews();
    },

    resetPage() {
      this.page = 1;
      this.totalPage = 1;
    },

    viewNews(news) {
      this.newsView = _.cloneDeep(news);
      this.$modal.show("modal-view-news");
    },

    show(news) {
      this.updateInfo = _.cloneDeep(news);
      this.$modal.show("news");
    },
  },

  created() {
    this.getNews();
  },
};
</script>
