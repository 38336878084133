<template>
  <modal :name="type" class="modal-inner" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
    <span class="close-button" @click="hide(type)"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span v-if="type == 'news'">Nyheter</span>
      <span v-else-if="type == 'link'">Länkar</span>
      <span v-else-if="type == 'info'">Info</span>
      <span v-else>Ändra</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateInternalInfo" v-if="updateInfo != null">
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <BaseInput v-model="updateInfo.title" type="text" field_name="Title" rules="required" />
          </div>
          <div v-if="updateInfo.type != 'upload_link' && updateInfo.type != 'external_link'" class="w-full px-2 py-2">
            <TextEditor v-model="updateInfo.description" />
          </div>
          <div v-if="updateInfo.type == 'upload_link' || updateInfo.type == 'external_link'" class="w-full px-2 py-2">
            <ValidationProvider name="Link type" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-700 text-sm font-bold pb-2 font-serif">Link type<b class="text-red-500 ml-1">*</b></label>
                <select v-model="updateInfo.type" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 hover:border-gray-400">
                  <option :value="o['id']" v-for="(o, i) in linkTypes" :key="i">{{ o["name"] }}</option>
                </select>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div v-if="updateInfo.type == 'upload_link'" class="w-full px-2 py-2">
            <label class="text-gray-600 text-sm font-bold pb-2 font-serif">File</label>
            <input type="file" ref="uploadFile" @change="fileChange()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
          </div>
          <div v-if="updateInfo.type == 'external_link'" class="w-full px-2 py-2">
            <BaseInput v-model="updateInfo.link" type="text" field_name="Link" rules="required" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide(type)">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateInternalInfo()">Spara<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 950;
import TextEditor from "@/components/text_editor/editor.vue";

export default {
  components: {
    TextEditor,
  },
  props: {
    type: {
      required: true,
      default: "news",
    },
    internalInfo: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      user: this.$store.state.user,
      linkTypes: [
        { id: "upload_link", name: "Upload" },
        { id: "external_link", name: "External" },
      ],
      updateInfo: null,
      updateFile: null,
    };
  },
  watch: {
    internalInfo(newVal) {
      // eslint-disable-line
      this.updateInfo = _.cloneDeep(newVal);
      this.updateFile = null;
    },
  },
  methods: {
    updateInternalInfo() {
      this.$refs.formUpdateInternalInfo.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.updateInfo._id) {
          if (this.updateInfo.type == "upload_link") {
            let headers = {
              "Content-Type": "multipart/form-data",
              "X-Access-Token": this.$store.state.token,
            };

            let formData = new FormData();

            formData.append("title", this.updateInfo.title);
            if (this.updateFile) formData.append("file", this.updateFile);

            this.axios
              .put(`${process.env.VUE_APP_SERVER_URL}/upload-link/${this.updateInfo._id}`, formData, headers)
              .then(() => {
                // eslint-disable-line
                this.$emit("updateInternalInfo");
                this.$modal.hide(this.type);
              })
              .catch((error) => {
                this.handleError(error);
              });
          } else {
            let data = {
              type: this.updateInfo.type,
              title: this.updateInfo.title,
              description: this.updateInfo.description,
              link: this.updateInfo.link,
            };

            this.axios
              .put(`${process.env.VUE_APP_SERVER_URL}/internal-info/${this.updateInfo._id}`, data)
              .then(() => {
                // eslint-disable-line
                this.$emit("updateInternalInfo");
                this.$modal.hide(this.type);
              })
              .catch((error) => {
                this.handleError(error);
              });
          }
        } else {
          if (this.updateInfo.type == "upload_link") {
            if (this.updateFile == null) {
              this.handleError(null, "Filfältet är obligatoriskt");
              return;
            }

            let headers = {
              "Content-Type": "multipart/form-data",
              "X-Access-Token": this.$store.state.token,
            };

            let formData = new FormData();

            formData.append("type", this.updateInfo.type);
            formData.append("title", this.updateInfo.title);
            formData.append("file", this.updateFile);

            this.axios
              .post(`${process.env.VUE_APP_SERVER_URL}/upload-link`, formData, headers)
              .then(() => {
                // eslint-disable-line
                this.$emit("addedNew");
                this.$emit("updateInternalInfo");
                this.$modal.hide(this.type);
              })
              .catch((error) => {
                this.handleError(error);
              });
          } else {
            let data = {
              type: this.updateInfo.type,
              title: this.updateInfo.title,
              description: this.updateInfo.description,
              link: this.updateInfo.link,
            };

            this.axios
              .post(`${process.env.VUE_APP_SERVER_URL}/internal-info`, data)
              .then(() => {
                // eslint-disable-line
                this.$emit("addedNew");
                this.$emit("updateInternalInfo");
                this.$modal.hide(this.type);
              })
              .catch((error) => {
                this.handleError(error);
              });
          }
        }
      });
    },

    fileChange() {
      if (this.$refs.uploadFile.files.length > 0) this.updateFile = this.$refs.uploadFile.files[0];
      else this.updateFile = null;
    },

    hide(id) {
      this.$refs.formUpdateInternalInfo.reset();
      this.updateFile = null;
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
