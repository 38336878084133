<template>
  <modal name="modal-view-news" class="modal-inner" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
    <span class="close-button" @click="hide()"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      Nyheter
    </div>
    <div class="modal-body less-bottom-padding" v-if="news != null">
      <div class="text-xl font-semibold font-serif border-b-2 border-secondary-300 pb-2 mb-2">
        {{ news.title }}
      </div>
      <div v-html="news.description" class="ProseMirror view"></div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 850;

export default {
  props: {
    news: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      user: this.$store.state.user,
    };
  },
  methods: {
    hide() {
      this.$modal.hide("modal-view-news");
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
